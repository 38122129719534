<template>
    <section class="w-100 position-relative">
        <div class="row mx-0 align-items-center br-t-8 px-3 bg-white" style="height:69px;">
            <div class="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-5 mx-2 date-picker-edit px-0">
                <el-date-picker
                v-model="fechas"
                class="shadow w-100"
                type="datetimerange"
                range-separator="-"
                size="small"
                start-placeholder="Fecha inicio"
                end-placeholder="Fecha fin"
                @change="listarPedidosMapa"
                />
            </div>
            <div v-loading="loading" class="col-auto mx-2 px-0">
                <div class="btn-general px-3 f-14" @click="listarPedidosMapa">
                    Mostrar Pedidos
                </div>
            </div>
            <div class="col-auto">
                <el-checkbox v-model="tipo_cedis" class="check-red text-white" @change="cargarDatosMapa">
                    Cedis
                </el-checkbox>
                <el-checkbox v-model="tipo_pedidos" class="check-red text-white" @change="cargarDatosMapa">
                    Pedidos
                </el-checkbox>
            </div>
        </div>
        <!-- Aqui va el contenido -->
        <mapa-google ref="GoogleMaps" v-loading="loading" height="calc(100vh - 208px)" />
        <!-- Pestaña -->
        <div v-loading="loading" class="bg-white border container-absolute" :style="`width: calc(50vw - ${expanded ? '300px' : '1500px'})`">
            <div v-if="expanded" class="row mx-0 align-items-center py-2 justify-center">
                <div class="col px-0 pl-3 mx-1">
                    <el-input v-model="search" placeholder="buscar por Id pedido" class="w-100 br-20" />
                </div>
                <div class="col-auto">
                    <el-popover
                    placement="bottom"
                    width="150"
                    trigger="click"
                    >
                        <div v-for="(filtro, f) in filtros" :key="f" class="row px-2 mx-0 my-3">
                            <el-checkbox v-model="filtro.estado" class="check-red">
                                {{ filtro.nombre_filtro }}
                            </el-checkbox>
                        </div>
                        <div class="bg-general px- py-2 br-12 text-white text-center f-18 mt-3 cr-pointer" @click="filtrar">
                            Filtrar
                        </div>
                        <div slot="reference" class="bg-whitesmoke border shadow br-12 px-1 py-1 cr-pointer ">
                            <i class="icon-filter-outline f-22" />
                        </div>
                    </el-popover>
                </div>
            </div>
            <div v-if="expanded" class="mt-2 custom-scroll overflow-auto" style="height:calc(100vh - 212px)">
                <div v-for="(pedido, idx) in pedidos_x_entregar" :key="idx" class="row mx-2 align-items-center py-2 border-top border-bottom">
                    <div class="col-xl col-md-12  f-17 tres-puntos text-general f-600 text-left cr-pointer" @click="verPedido(pedido)">
                        ID. {{ pedido.id }}
                    </div>
                    <div class="col-xl col-md-12  f-17 tres-puntos text-lg-left text-xl-right text-gr-general f-600 cr-pointer" @click="verPedido(pedido)">
                        ${{ convertMoney(pedido.valor_final, pedido.idm_moneda, false) }}
                        <el-tooltip placement="bottom" :content="textoTooltip(pedido.estado)" effect="light">
                            <i class="f-22" :class="iconosPedidos(pedido.estado)" />
                        </el-tooltip>
                    </div>
                    <div class="col-12 tres-puntos cr-pointer" :class="pedido.retrasado ? 'text-general-red' : 'text-general'" @click="verPedido(pedido)">
                        <i class="icon-calendar-clock f-22 f-600" />
                        <span>
                            {{ pedido.horario_format }}
                        </span>
                    </div>
                </div>
            </div>
            <div
            class="position-absolute sale-tab border initial-position d-middle-center cr-pointer initial-position"
            @click="expanded = !expanded"
            >
                <i :class="expanded ? 'icon-angle-left' : 'icon-angle-right'" />
            </div>
        </div>
        <!-- Partials -->
        <modal-pedido ref="VerPedido" @actualizar="actualizarFechasHoras" />
    </section>
</template>

<script>
import PedidosMapa from '~/services/almacen/pedidos_mapa'
import {mapGetters} from 'vuex'
import moment from 'moment'

const contentString = (e) => {
    const contentString = `
        <div style="width:100%;height:100%;border-radius:12px;position:relative;">
            <div class="py-2 px-3 tres-puntos" style="position:absolute;border-radius:12px;color:#fff;font-weight:bold;background-color:#232320;bottom:0px;right:0px;max-width:150px;">
                ${e.nombre}
            </div>
            <img src="${e.imagen}" width="100%" height="100%" style="border-radius:12px;object-fit:cover;" />
        </div>
    `;
    return { lat: e.latitud, lng: e.longitud, icon: '/img/icons/cedis-point-map.svg', info: contentString}
}

const contentPedido = (e) => {
    const info = `
        <div style="width:160px;height:50px;border-radius:12px;position:relative;">
            <div class="py-2 px-3 mt-2 ml-3" style="position:absolute;border-radius:12px;font-weight:bold;font-size:18px">
                Pedido ${e.id}
            </div>
        </div>
    `;
    return { lat: e.latitud, lng: e.longitud, icon: '/img/icons/elipse.svg', label: '1', info}

}
export default {
    components: {
        modalPedido: () => import('./partials/modalPedido')
    },
    data(){
        return {
            loading: false,
            locations_cedis:[],
            locations_pedidos: [],
            tipo_cedis: true,
            tipo_pedidos: true,
            fechas: [moment('2021-01-20').startOf('day').toDate(), moment().endOf('day').toDate()],
            admin_pedidos:[],
            value: '',
            expanded: false,
            search: '',
            filtrosActivos:[1,2,201,202,3],
            filtros: [
                { id: 1, nombre_filtro: 'Sin confirmar', estado: true },
                { id: 2, nombre_filtro: 'Confirmado', estado: true },
                { id: 201, nombre_filtro: 'Alistado', estado: true},
                { id: 202, nombre_filtro: 'Empacado', estado: true, },
                { id: 3, nombre_filtro: 'Enviado', estado: true }
            ]
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
        pedidos_x_entregar(){
            const pedidosFiltrados = this.admin_pedidos.filter(f => this.filtrosActivos.includes(f.estado))
            if(this.search !== null && this.search !== ''){
                return pedidosFiltrados.filter(el => {
                    return el.id ==(parseInt(this.search))
                })
            }else{
                return pedidosFiltrados
            }
        }
    },
    watch:{
        id_cedis(){
            this.listarPedidosMapa()
        },
    },
    mounted(){
        this.limpiarVista()
        this.listarPedidosMapa()
    },
    methods: {
        async listarPedidosMapa(){
            try {
                const params = {
                    id_cedis: this.id_cedis,
                    fechas: this.fechas
                }
                this.loading = true
                const {data} = await PedidosMapa.listar_pedidos_mapa(params)
                console.log(data);
                this.loading = false
                this.admin_pedidos = data.admin_pedidos
                var cedis_format = data.cedis.map(contentString)
                var pedidos_format = this.admin_pedidos.map(contentPedido)
                // simular prueba carga de bastantes datos
                // for (let index = 0; index < 320; index++){
                //     pedidos_format.push({ lat: 7.113155796824107, lng: -73.11218109229623, icon: '/img/icons/maps_pedidos.svg', label: 'A'})
                // }
                // for (let index = 0; index < 200; index++){
                //     pedidos_format.push({ lat: 7.073001732082648, lng: -73.10760112641731, icon: '/img/icons/maps_pedidos.svg', label: 'B'})
                // }
                // for (let index = 0; index < 80; index++){
                //     pedidos_format.push({ lat: 7.097396308413209, lng: -73.09833988780932, icon: '/img/icons/maps_pedidos.svg', label: 'C'})
                // }
                // for (let index = 0; index < 80; index++){
                //     pedidos_format.push({ lat: 7.0791961020996546, lng: -73.08926775710046, icon: '/img/icons/maps_pedidos.svg', label: 'C'})
                // }

                this.locations_cedis = cedis_format
                this.locations_pedidos = pedidos_format
                this.cargarDatosMapa()
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        iconosPedidos(estado){
            switch(estado){
            case 1:
                return 'icon-receipt';
            case 2:
                return 'icon-ok-circled';
            case 201:
                return 'icon-cart-arrow-down';
            case 202:
                return 'icon-shopping ';
            case 3:
                return 'icon-truck-fast-outline';
            }
        },
        textoTooltip(estado){
            switch(estado){
            case 1:
                return 'Sin Confirmar';
            case 2:
                return 'Confirmado';
            case 201:
                return 'Alistado';
            case 202:
                return 'Empacado';
            case 3:
                return 'Enviado';
            }
        },
        verPedido(pedido){
            this.$refs.VerPedido.toggle(pedido);
        },
        actualizarFechasHoras(adminPedido){
            const indexOfItem =this.admin_pedidos.findIndex(e => e.id ===adminPedido.id)
            if(indexOfItem >= 0){
                const founPedido =  this.admin_pedidos[indexOfItem]
                const newValue = {...founPedido,
                    entrega_fecha:adminPedido.entrega_fecha,
                    entrega_horario: adminPedido.entrega_horario,
                    horario_format: adminPedido.horario_format,
                    retrasado: adminPedido.retrasado
                }
                this.$set(this.admin_pedidos, indexOfItem, newValue)

            }
        },
        cargarDatosMapa(){
            var cedis_pedidos = []
            if(this.tipo_cedis){
                cedis_pedidos = cedis_pedidos.concat(this.locations_cedis);
                // console.log('priemr if', cedis_pedidos );
            }
            console.log(this.tipo_pedidos);
            if(this.tipo_pedidos){
                cedis_pedidos = cedis_pedidos.concat(this.locations_pedidos);
                // console.log('segundo if', cedis_pedidos );

            }
            var locations = []
            locations = cedis_pedidos;
            console.log('locations', locations
            ); // quitar cuando este estable

            this.$refs.GoogleMaps.initMapMarkerClusterer(locations, true, true)

        },
        filtrar(){
            const filtrosActivos = this.filtros.filter(f => f.estado)
            this.filtrosActivos = filtrosActivos.map(e => e.id)
            const pedidosFiltrados = this.admin_pedidos.filter(f => this.filtrosActivos.includes(f.estado))
            var pedidos_format = pedidosFiltrados.map(e => {
                return   { lat: e.latitud, lng: e.longitud, icon: '/img/icons/elipse.svg', label: '1'}
            })
            this.locations_pedidos = pedidos_format
            this.cargarDatosMapa()

        },
        limpiarVista(){
            this.filtrosActivos=[1,2,201,202,3]
            this.search= ''
        }
    }
}
</script>

<style lang="scss" scoped>
.br-12{ border-radius: 12px; }
.sale-tab{
    border-radius: 0px 12px 12px 0px;
    color: #5D5D5D;
    background-color:#FFFFFF;
    height: 61px;
    z-index: 5;
    font-size: 18px;
    right: -28px;
    top: 126px;
}
.container-absolute{
    position: absolute;
    height: 100%;
    z-index:5;
    left:0;
    top:0;
}

.text-red{ color: #FF2525; }
</style>
