import axios from 'axios'

const API = 'almacen-mapa'

const Pedidos_mapa = {

    listar_pedidos_mapa(params){
        return axios(`${API}/pedidos`,{params})
    },
    detalle_pedido_mapa(payload){
        return axios(`${API}/${payload.id_admin_pedido}/detalle`)
    },
    entrega_fecha(payload){
        return axios.put(`${API}/entrega-fecha`, payload)
    },



}

export default Pedidos_mapa
